<template>
    <div>    
        <div class="row">
            <div class="col-4">
                <input
                    class="form-control"
                    placeholder="DD"
                    v-model="day"
                    @blur="update"
                >
            </div>

            <div class="col-4">
                <input
                    class="form-control"
                    placeholder="MM"
                    v-model="month"
                    @blur="update"
                >
            </div>

            <div class="col-4">
                <input
                    class="form-control"
                    placeholder="YYYY"
                    v-model="year"
                    @blur="update"
                >
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['question'],

    data() {
        return {
            value: null,
            day: null,
            month: null,
            year: null,
        }
    },

    methods: {
        updateOnBlur: function (event) {
            this.update(event, true);
        },
        update: function (event, blur = false) {
            if (!this.day || !this.month || !this.year) {
                return;
            }

            this.value = this.year + '-' + this.month + '-' + this.day;

            let data = {
                key: this.question.key,
                value: this.value,
                blur: blur
            }

            this.$emit('updated', data);
        },
    }    
}
</script>